<template>
  <div
    :class="['hint', `hint--${position}`]"
    @click="show"
    v-click-outside="clickOutside"
    @mouseover="show"
    @mouseleave="clickOutside"
  >
    <div v-if="isShow" :class="['hint__content', `hint__content--${position}`]">
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";

const props = defineProps({
  position: {
    type: String,
    default: "bottom",
    validator: function (value) {
      return ["bottom", "left", "right"].includes(value);
    },
  },
});

let isShow = ref(false);

const show = () => {
  isShow.value = true;
};

const clickOutside = (val) => {
  isShow.value = false;
};
</script>

<style lang="scss" scoped>
.hint {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  cursor: pointer;
  height: 18px;
  width: 18px;

  &::before {
    content: "";
    cursor: pointer;
    width: 100%;
    height: 100%;
    mask-image: url("assets/img/question.svg");
    background-color: #C0C3D3;
    mask-repeat: no-repeat;
    mask-size: cover;
  }


  &--bottom {
    align-items: flex-start;
  }

  &__content {
    width: 210px;
    position: absolute;
    padding: 10px 12px;
    border-radius: 6px;
    background: #4d4d4d;
    color: var(--color-white);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    z-index: 1;

    &:after {
      content: "";
      position: absolute;
      width: 10px;
      height: 10px;
      background: #4d4d4d;
      transform: rotate(45deg) translateY(-7px);
    }

    &--right right {
      left: 30px;

      &:after {
        left: -10px;
        top: 50%;
      }
    }

    &--left {
      left: -225px;

      &:after {
        right: 0;
        top: 50%;
      }
    }

    &--bottom {
      top: 30px;

      &:after {
        top: 1px;
        left: 50%;
        transform: rotate(45deg) translate(-8px, 0px);
      }
    }
  }
}
</style>
