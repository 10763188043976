<i18n lang="json">
{
	"ru": {
    "nights": "менее суток | {n} сутки | {n} суток | {n} суток",
    "sale": "скидка по цене от",
    "clean": "плата за уборку",
    "hot": "Горящее предложение",
    "showCalc": "Показать расчёт",
    "hideCalc": "Скрыть расчёт",
    "bonusInfo": "Бонусы применены",
    "calcText": {
      "calcSeason": "по сезонной цене",
      "DopPersonsSeason": "сезонная доплата за {n} гостей | сезонная доплата за {n} гостя | сезонная доплата за {n} гостя | сезонная доплата за {n} гостей",
      "DopPersons": "доплата за {n} гостей | доплата за {n} гостя | доплата за {n} гостей",
      "early": "за ранний заезд",
      "late": "за поздний отъезд"
    }
	},
	"en": {
    "nights": "less night | {n} night | {n} nights | {n} nights",
    "sale": "for the price of",
    "clean": "cleaning fee",
    "hot": "Hot sale",
    "showCalc": "View calculate",
    "hideCalc": "Hide calculate",
    "bonusInfo": "Bonuses applied",
    "calcText": {
      "calcSeason": "at a seasonal price",
      "DopPersonsSeason": "seasonal surcharge for 0 guests | seasonal surcharge for {n} guest | seasonal surcharge for {n} guests | seasonal surcharge for {n} guests",
      "DopPersons": "surcharge for 0 guests | surcharge for {n} guest | surcharge for {n} guests | surcharge for {n} guests",
      "early": "for early check-in",
      "late": "for late departure"
    }
	}
}
</i18n>
<template>
  <div
    class="booking__calc"
    :class="{ booking__calc_hot: hotSale && viewCalc }"
  >
    <template v-if="viewCalc">
      <div v-for="calc in data" class="calc__item">
        <template v-if="calc.type == 'interval' || calc.type == 'sale'">
          <div class="calc__result_sale">
            <span v-if="calc.type == 'interval'">{{
              formatNum(calc.base)
            }}</span>
            <span v-else-if="calc.cost_old">{{
              formatNum(calc.cost_old)
            }}</span>
          </div>
          <div class="calc__result">
            <span>
              -
              {{ formatNum(calc.cost) }} &#215;
              {{ t("nights", calc.nights) }}
            </span>
            <span
                v-if="calc.type == 'interval'"
                :class="{
                'calc__sale-price':
                  calc.type == 'interval' || calc.type == 'sale',
                'color-black': calc.type !== 'interval',
              }"
            >
              {{ formatNum(calc.base - calc.cost) }}
            </span>
            <span
              v-else
              :class="{
                'calc__sale-price':
                  calc.type == 'interval' || calc.type == 'sale',
                'color-black': calc.type !== 'interval',
              }"
            >
              {{ formatNum(calc.base - calc.cost * calc.nights) }}
            </span>
          </div>
          <div class="calc__text_sale">
            <!-- <span class="calc__text_sticker"></span> -->
            <div class="calc__text_sale-text">
              <span v-if="calc.type == 'interval'" class="tmp-font--small">
                {{ t("sale") }}
                {{ t("nights", calc.nights) }}
              </span>
              <div v-else class="sale-content color-black tmp-font--small">
                <span class="sale-content__text">
                  {{  t("hot")  }}
                </span>
                <SaleHint />
              </div>
            </div>
          </div>
        </template>

        <template v-else>
          <div v-if="calc.type == 'dop_persons'" class="calc__result">
            <span>
              {{ formatNum(calc.cost) }} &#215;
              {{ t("nights", calc.nights) }}
            </span>
            <span>{{ formatNum(calc.cost * calc.nights) }}</span>
          </div>
          <div v-else-if="Object.entries(calc).length !== 0" class="calc__result">
            <span>
              {{ formatNum(calc.cost) }} &#215;
              {{ t("nights", calc.nights) }}
            </span>
            <span>{{ formatNum(calc.cost * calc.nights) }}</span>
          </div>
          <div v-if="calc.type && calc.type != ''" class="calc__text">
            <span
              v-if="calc.type == 'season' || calc.type == 'season_price'"
              class="tmp-font--small"
              >{{ t("calcText.calcSeason") }}</span
            >
            <span v-else-if="calc.type == 'early'" class="tmp-font--small">{{
              t("calcText.early")
            }}</span>
            <span v-else-if="calc.type == 'late'" class="tmp-font--small">{{
              t("calcText.late")
            }}</span>
            <span
              v-else-if="calc.type == 'dop_persons_season'"
              class="tmp-font--small"
              >{{ t("calcText.DopPersonsSeason", calc.guests) }}</span
            >
            <span
              v-else-if="calc.type == 'dop_persons'"
              class="tmp-font--small"
              >{{ t("calcText.DopPersons", calc.guests) }}</span
            >
          </div>
        </template>
      </div>
      <div v-for="calcs in dop" class="calc__item">
        <div>
          <div v-if="calcs.tp === 'cleaning' && calcs.cost" class="calc__result">
            <span>{{ t("clean") }}</span>
            <span>{{ formatNum(calcs.cost) }}</span>
          </div>
        </div>
      </div>
      <div v-for="item in more" class="calc__item">
        <div>
          <div class="calc__result">
            <span>{{ item.text }}</span>
            <span>{{ formatNum(item.value) }}</span>
          </div>
        </div>
      </div>
    </template>
    <button
      v-if="slide"
      class="calc__button button button_link"
      @click.prevent="viewPriceCalc"
    >
      {{ viewCalc ? t("hideCalc") : t("showCalc") }}
    </button>
    <div v-if="sale" class="booking__bonus-sale">
      <span class="booking__bonus-sale-summ">
        - {{ formatCalc(sale) }}
        <span class="booking__bonus-sale-cliff"></span>
        <span class="booking__bonus-sale-cliff"></span>
        <span class="booking__bonus-sale-cliff"></span>
      </span>
      <span class="booking__bonus-sale-info">
        {{ t("bonusInfo") }}
        <span class="booking__bonus-sale-cliff"></span>
        <span class="booking__bonus-sale-cliff"></span>
        <span class="booking__bonus-sale-cliff"></span>
      </span>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { event as sendEventGtag } from "@/composables/gtag/index.js";
import { useI18n } from 'vue-i18n';
import SaleHint from "@/components/base/PriceCalcDetail/SaleHint.vue";

export default {
  name: "BaseCalcPrice",
  props: {
    data: {
      type: Array,
    },
    currency: {
      type: Object,
    },
    dop: {
      type: Array,
    },
    more: {
      type: Array,
    },
    hot: {
      default: false,
    },
    slide: {
      type: Boolean,
      default: true,
    },
    price: {
      type: Object,
      default: () => {},
    },
    sale: {
      type: Number,
      default: 0,
    },
  },
  setup() {
    const { t } = useI18n();
    return {
      t
    }
  },
  components: {
    SaleHint
  },
  data() {
    return {
      viewCalc: false,
    };
  },
  computed: {
    ...mapGetters("detail", ["getObjectPrice"]),
    hotSale() {
      let sale = Object.values(this?.data)?.find(
        (item) => item.type === "sale" || item.type === "interval"
      );
      return sale ? sale : null;
    },
    intervalObject() {
      return this.data.find(el => el.type === 'interval')
    },
    calcCurrency() {
      return this.getObjectPrice?.currency;
    },
  },
  watch: {
    slide(val) {
      if (!val) {
        this.viewCalc = true;
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (!this.slide) {
        this.viewCalc = true;
      }
    });
  },
  methods: {
    formatNum(item) {
      return new Intl.NumberFormat(this.$i18n.locale, {
        style: "currency",
        currency: this.currency.title,
        minimumFractionDigits: 0,
      }).format(item);
    },
    viewPriceCalc() {
      this.viewCalc = !this.viewCalc;
      if (this.price) {
        // this.$ga.event('draft_sa_m', 'click', 'btn_price_more', this.price?.price || 0);
        sendEventGtag("draft_sa_m", {
          click: "btn_price_more",
          value: this.price?.price || 0,
        });
      }
    },
    formatCalc(item) {
      return new Intl.NumberFormat(this.$i18n.locale, {
        style: "currency",
        currency: (this.calcCurrency && this.calcCurrency.code) || "RUB",
        minimumFractionDigits: 0,
      }).format(item);
    },
  },
};
</script>
<style lang="scss" scoped>
.booking {
  &__sale {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 10px;

    .sale {
      &__icon {
        min-width: 43px;
        .icon {
          width: 34px;
          border-radius: 2px;
          background: #e10600;
          font-size: 12px;
          line-height: 18px;
          color: #ffffff;
          text-align: center;
          position: relative;
          padding-left: 3px;
          white-space: nowrap;

          &:before {
            border-left: 9px solid #e10600;
            border-top: 9px solid transparent;
            border-bottom: 9px solid transparent;
            border-bottom-left-radius: 2px;
            border-top-left-radius: 2px;

            content: "";
            display: block;
            height: 0;
            width: 0;

            position: absolute;
            right: -8px;
            top: 0;
          }
          &:after {
            content: "";
            display: block;
            width: 2px;

            position: absolute;
            right: -9px;
            top: 0;
            bottom: 0;

            background: #fff;
          }
        }
      }
      &__price {
        font-size: 17px;
        line-height: 25px;
        text-align: center;
        text-decoration-line: line-through;
        color: #717171;
        padding-left: 6px;
      }
    }
  }
  &__calc {
    &_hot {
      padding-top: 10px;
    }
    .calc {
      &__button {
        margin-top: 10px;
      }
      &__item {
        display: flex;
        flex-direction: column;

        padding-top: 10px;
        &:first-child {
          padding-top: 20px;
        }
      }
      &__result {
        display: flex;
        justify-content: space-between;

        width: 100%;
        font-size: 14px;
        line-height: 16px;

        &_sale {
          font-size: 14px;
          line-height: 16px;
          text-decoration-line: line-through;
          color: #717171;
        }
      }
      &__sale-price {
        color: #e10600;
        &.color-black {
          color: #000000;
        }
      }
      &__text {
        font-size: 14px;
        line-height: 16px;
        color: #717171;
        &_sticker {
          display: block;
          background: url("~/assets/img/sale-sticker.svg");
          background-size: 100%;
          background-repeat: no-repeat;
          background-position: center center;
          width: 16px;
          height: 16px;
        }
        // &_sale-text {
        //   padding-left: 6px;
        // }
        &_sale-text {
          .color-black {
            color: #000000;
          }
        }
        &_sale {
          font-size: 14px;
          line-height: 16px;
          color: #e10600;
          display: flex;
        }
      }
    }
  }
}
.booking__bonus-sale {
  display: flex;
  align-items: center;
  margin-top: 22px;
  &-summ,
  &-info {
    display: block;
    width: 100%;
    height: 27px;
    padding: 6px 9px;
    color: #417505;
    font-size: 12px;
    position: relative;
  }
  &-summ {
    width: auto;
    white-space: nowrap;
    margin-right: 2px;
    font-size: 14px;
    &:before {
      content: "";
      background-color: rgba(255, 169, 0, 0.1);
      transform: rotate(-3deg) translateY(1px);
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }
  }
  &-info {
    background-color: rgba(255, 169, 0, 0.1);
  }
  &-cliff {
    display: block;
    width: 6px;
    height: 4px;
    border-radius: 50%;
    background-color: #fff;
    position: absolute;
    right: -2px;
    top: 3px;
    &:nth-child(2) {
      top: 10px;
      right: -3px;
    }
    &:nth-child(3) {
      top: 18px;
      right: -3px;
    }
  }
  &-info &-cliff {
    right: unset;
    left: -3px;
  }
}
.sale-content {
  display: flex;
  align-items: center;
  margin-top: 4px;
}
</style>
