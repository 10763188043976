<i18n lang="json">
  {
    "ru": {
      "sale": "При заселении сегодня до {time}",
      "guests": "(без гостей, | (максимум {n} гость, | (максимум {n} гостя, | (максимум {n} гостей,",
      "days": "менее суток проживания)| минимум {n} сутки проживания) | минимум {n} суток проживания) | минимум {n} суток проживания)"
    },
    "en": {
      "sale": "Check-in today until {time}",
      "guests": "(no guests, | (maximum {n} guest, | (maximum {n} guest, | (maximum {n} guest,",
      "days": "less than a day's stay)| minimum {n} per night stay) | minimum {n} per night stay) | minimum {n} per night stay)"
    }
  }
</i18n>

<template>
  <Hint position="bottom">
    <template #content>
      <p v-html="getSaleText" /> 
    </template>
  </Hint>
</template>

<script setup>
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import Hint from "@/components/base/Hint.vue";

const { t } = useI18n();
const store = useStore();

const getSaleText = computed(() => {
  const hotSale = store.getters["detail/getObjectData"]?.hot_sale || {};
  let checkMax = "00:00";

  if(hotSale.check_max && +hotSale.check_max !== 24) {
    checkMax = `${hotSale.check_max}:00`;
  }

  return `${t("sale", { time: checkMax })}
  ${t("guests", hotSale.max_guests ? hotSale.max_guests : 0)} 
  ${t("days", hotSale.min_nights ? hotSale.min_nights : 0)}`;
})

</script>

<style scoped lang="scss">
.hint {
  margin-left: 4px;

  &::before {
    background-color: #1C1C1C;
  }
}

:deep(.hint__content) {
  min-width: 250px;
}
</style>
